<template>
    <el-main>
        <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
            <el-form-item label="赛程名称：" prop="events_name">
                <el-input v-model="addForm.events_name"></el-input>
            </el-form-item>
            <el-form-item label="赛程活动：" prop="activity_id">
                <el-select v-model="addForm.activity_id">
                    <el-option v-for="(item, index) in activityList" :key="index" :label="item.activity_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="banner图片：" prop="banner">
                <ReadyUploadSource @getSource="getServiceImg" @changeOrder="changeOrder" @removeThis="removeImageThis"
                    :isMany="true" :manyPath="addForm.banner" :showStyle="{ width: '175px', height: '100px' }">
                </ReadyUploadSource>
                <p style="color: #999; font-size: 13px">建议图片尺寸600*400px，大小不超过1M</p>
            </el-form-item>
            <el-form-item label="赛程图片：" prop="picture">
                <ReadyUploadSource :showStyle="{ width: '150px', height: '75px' }"
                    @getSource="val => (addForm.picture = val.path)" :path="addForm.picture"
                    @removeThis="() => (addForm.picture = '')"></ReadyUploadSource>
                <p style="color: #999; font-size: 13px">建议图片尺寸300*150px</p>
            </el-form-item>
            <el-form-item label="赛程分享图：" prop="share_picture">
                <ReadyUploadSource @getSource="val => (addForm.share_picture = val.path)" :path="addForm.share_picture"
                    :showStyle="{ width: '140px', height: '100px' }" @removeThis="() => (addForm.share_picture = '')">
                </ReadyUploadSource>
                <p style="color: #999; font-size: 13px">建议图片尺寸500*400px</p>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
                <el-input v-model="addForm.sort"></el-input>
            </el-form-item>
            <el-form-item label="队伍人数：" prop="team_count">
                <el-input v-model="addForm.team_count"></el-input>
            </el-form-item>
            <el-form-item label="是否开启：">
                <el-switch v-model="addForm.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="赛事奖励详情：" prop="details">
                <RichText width="500" height="200" :richTxt="addForm.details" @soninfo="val => (addForm.details = val)">
                </RichText>
            </el-form-item>
        </el-form>
        <Preservation @preservation="submit">
            <router-link to="/extension/activity/scheduleList" slot="return" v-if="id" style="margin-right: 10px">
                <el-button>返回</el-button>
            </router-link>
        </Preservation>
    </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
    components: {
        ReadyUploadSource,
        Preservation,
        RichText,
    },
    data () {
        return {
            id: '',
            addForm: {
                activity_id: '',
                events_name: '',
                picture: '',
                banner: [],
                apply_start_time: '',
                apply_end_time: '',
                details: '',
                team_count: 6,
                sort: '',
                banner: [],
                status: 1,
                share_picture: '',
            },
            activityList: [],
            rules: {
                events_name: [
                    {
                        required: true,
                        message: '请填写赛程名称',
                        trigger: 'blur',
                    },
                ],
                banner: [
                    {
                        required: true,
                        message: '请添加banner图片',
                        trigger: 'blur',
                    },
                ],
                activity_id: [
                    {
                        required: true,
                        message: '请选择赛程活动',
                        trigger: 'blur',
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: '请填写排序',
                        trigger: 'blur',
                    },
                ],
                picture: [
                    {
                        required: true,
                        message: '请添加赛程图片',
                        trigger: 'blur',
                    },
                ],
                team_count: [
                    {
                        required: true,
                        message: '请填写队伍人数',
                        trigger: 'blur',
                    },
                ],
                details: [
                    {
                        required: true,
                        message: '请填写赛事奖励详情',
                        trigger: 'blur',
                    },
                ],
                share_picture: [
                    {
                        required: true,
                        message: '请添加赛程分享图',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    created () {
        this.getActivity();
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getInfo();
        }
    },
    methods: {
        //获取banner图片
        getServiceImg (val) {
            let arr = [...this.addForm.banner];
            for (let i in val) arr.push(val[i].path);
            this.addForm.banner = arr;
        },
        // 拖动重新赋值数据
        changeOrder (val) {
            this.addForm.banner = val;
        },
        // 删除单张图片
        removeImageThis (index) {
            this.addForm.banner.splice(index, 1);
        },
        getInfo () {
            this.$axios
                .post(this.$api.sports.scheduleInfo, {
                    id: this.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        let addForm = this.addForm;
                        let info = res.result;
                        addForm.events_name = info.events_name;
                        addForm.activity_id = info.activity_id;
                        addForm.picture = info.picture;
                        addForm.banner = info.banner ? JSON.parse(info.banner) : [];
                        addForm.sort = info.sort;
                        addForm.apply_end_time = info.apply_end_time * 1000;
                        addForm.apply_start_time = info.apply_start_time * 1000;
                        addForm.banner = JSON.parse(info.banner);
                        addForm.team_count = info.team_count;
                        addForm.details = info.details;
                        addForm.share_picture = info.share_picture;
                        addForm.id = info.id;
                    }
                });
        },
        getActivity () {
            this.$axios.post(this.$api.sports.activityLists).then(res => {
                if (res.code == 0) {
                    this.activityList = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        submit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let addForm = _.cloneDeep(this.addForm);
                    if (this.id) addForm.id = this.id;
                    this.$axios.post(this.id ? this.$api.sports.editSchedule : this.$api.sports.addSchedule, addForm).then(res => {
                        if (res.code == 0) {
                            this.$router.push('/extension/activity/scheduleList');
                            this.$message.success(this.id ? '编辑成功' : '添加成功');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    padding-bottom: 50px;
    min-height: 100%;

    .el-form {

        .el-input,
        .el-textarea {
            width: 500px;
        }

        .el-date-editor {
            width: 240px;
        }

        .date {
            display: flex;
        }
    }
}
</style>
